import React from "react";
import { Link } from "../components/link";
import { Typography } from "@material-ui/core";

import Layout from "../layouts/layout";
import SEO from "../components/seo";

const Aboutpage = () => (
  <Layout>
    <>
      <SEO title="まるっと名言集について" />
      <Typography
        component="h1"
        style={{ margin: "12px 0", fontWeight: "bold" }}
      >
        運営者情報
      </Typography>
      <Typography component={"p"}>まるっと名言集運営事務局</Typography>
      <Typography component={"p"}>
        お問い合わせは
        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSf0AzbaUbtRwM051_7LA9wghm78a3gYfLAaPb2EjO_xDlCgog/viewform">
          こちら
        </Link>
      </Typography>
      <Link to="/">トップページへ</Link>
    </>
  </Layout>
);

export default Aboutpage;
